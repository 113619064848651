<div class="row wrapper border-bottom white-bg page-heading">
  <h2>Sugars</h2>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div
      *ngIf="message"
      class="m-md col-lg-12 alert alert-success alert-dismissable"
    >
      <button
        aria-hidden="true"
        data-dismiss="alert"
        class="close"
        type="button"
      >
        ×
      </button>
      {{ message }}
    </div>
    <div *ngFor="let product of products" class="col-lg-4">
      <div class="ibox">
        <div class="ibox-content product-box">
          <div class="product-imitation">
            <img src="{{ product.imageUrl }}" class="img-responsive" />
          </div>
          <div class="product-desc">
            <span class="product-price"> S$ {{ product.price }} </span>
            <small class="text-muted">Sugar</small>
            <span class="product-name">{{ product.title }}</span>
            <div class="row">
              <div class="col-6">
                <a
                  [routerLink]="['/product-detail', product.id]"
                  class="btn btn-sm btn-outline btn-primary"
                  >Info <i class="fa fa-long-arrow-right"></i>
                </a>
              </div>
              <div class="col-6 text-right">
                <div (click)="cartPlus(product)" class="btn btn-sm btn-primary">
                  <i class="fa fa-plus"></i> Add to Cart
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
