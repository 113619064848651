import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService, PortalService } from "../../_services";
import { User } from "./../../_models";

@Component({
  selector: "app-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.css"],
})
export class CartComponent implements OnInit {
  currentUser: User;
  count;
  total;
  products;

  constructor(
    private router: Router,
    private authService: AuthService,
    private portalService: PortalService
  ) {
    this.currentUser = this.authService.currentUserValue;
  }

  ngOnInit(): void {
    this.count = 0;
    this.total = 0;
    this.products = [];
    this.loadCart();
  }

  loadCart() {
    this.count = 0;
    this.total = 0;
    this.products = [];

    const cartItems = JSON.parse(localStorage.getItem("cart"));
    if (cartItems !== null) {
      this.count = cartItems.count;
      this.total = cartItems.totalAmount;
      this.products = [];

      for (const key in cartItems.items) {
        this.products.push({
          id: key,
          price: cartItems.items[key].price,
          title: cartItems.items[key].title,
          quantity: cartItems.items[key].quantity,
          sum: cartItems.items[key].sum,
        });
      }
    }
  }

  cartPlus(p) {
    this.portalService.cartPlus(p).then(() => {
      this.loadCart();
    });
  }

  cartMinus(p) {
    this.portalService.cartMinus(p).then(() => {
      this.loadCart();
    });
  }
}
