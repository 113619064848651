import { Component, OnInit } from "@angular/core";
import { PortalService } from "../../_services";
declare var jQuery: any;

@Component({
  selector: "app-stock",
  templateUrl: "./stock.component.html",
  styleUrls: ["./stock.component.css"],
})
export class StockComponent implements OnInit {
  stocks: Array<any>;

  constructor(private portalService: PortalService) {}

  ngOnInit(): void {
    jQuery(".ibox-content").toggleClass("sk-loading");
    this.loadStock();
  }

  loadStock() {
    this.stocks = [];
    this.portalService.fetchStock().then((response) => {
      this.stocks = response;
      jQuery(".ibox-content").toggleClass("sk-loading");
    });
  }
}
