<div class="middle-box text-center loginscreen animated fadeInDown">
  <div *ngIf="success" class="alert alert-success mt-3 mb-0">{{ success }}</div>
  <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{ error }}</div>
  <div class="ibox-title">
    <h2>Forgot Password</h2>
  </div>
  <div class="ibox-content">
    <div class="sk-spinner sk-spinner-three-bounce">
      <div class="sk-bounce1"></div>
      <div class="sk-bounce2"></div>
      <div class="sk-bounce3"></div>
    </div>
    <form
      *ngIf="sendCodeFlag"
      [formGroup]="sendCodeForm"
      (ngSubmit)="sendCode()"
    >
      <div class="form-group">
        <input
          type="text"
          placeholder="Email"
          formControlName="email"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
        />
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
          <div *ngIf="f.email.errors.required">Email is required</div>
          <div *ngIf="f.email.errors.email">
            Email must be a valid email address
          </div>
        </div>
      </div>
      <div class="form-group">
        <button type="submit" class="btn btn-primary block full-width m-b">
          Send Code
        </button>
      </div>
      <a href="#" [routerLink]="['/login']"
        ><small>Remember password?</small></a
      >
      <p class="text-muted text-center">
        <small>Do not have an account?</small>
      </p>
      <a
        class="btn btn-sm btn-white btn-block"
        href="#"
        [routerLink]="['/register']"
        >Create an account</a
      >
    </form>

    <form
      *ngIf="!sendCodeFlag"
      [formGroup]="verifyCodeForm"
      (ngSubmit)="verifyCode()"
    >
      <div class="form-group">
        <input
          type="text"
          placeholder="Email"
          formControlName="email"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f1.email.errors }"
        />
        <div *ngIf="submitted && f1.email.errors" class="invalid-feedback">
          <div *ngIf="f1.email.errors.required">Email is required</div>
          <div *ngIf="f1.email.errors.email">
            Email must be a valid email address
          </div>
        </div>
      </div>

      <div class="form-group">
        <input
          type="password"
          placeholder="New Password"
          formControlName="password"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f1.password.errors }"
        />
        <div *ngIf="submitted && f1.password.errors" class="invalid-feedback">
          <div *ngIf="f1.password.errors.required">
            New Password is required
          </div>
          <div *ngIf="f1.password.errors.minlength">
            Password must be at least 6 characters
          </div>
        </div>
      </div>

      <div class="form-group">
        <input
          type="text"
          placeholder="Code"
          formControlName="code"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f1.code.errors }"
        />
        <div *ngIf="submitted && f1.code.errors" class="invalid-feedback">
          <div *ngIf="f1.code.errors.required">Code is required</div>
        </div>
      </div>

      <div class="form-group">
        <button type="submit" class="btn btn-primary block full-width m-b">
          Submit
        </button>
      </div>
      <a href="#" [routerLink]="['/login']"
        ><small>Remember password?</small></a
      >
      <p class="text-muted text-center">
        <small>Do not have an account?</small>
      </p>
      <a
        class="btn btn-sm btn-white btn-block"
        href="#"
        [routerLink]="['/register']"
        >Create an account</a
      >
    </form>
  </div>
</div>
