<div class="row wrapper border-bottom white-bg page-heading">
  <h2>Inventory</h2>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="ibox">
      <div class="ibox-content">
        <div class="sk-spinner sk-spinner-three-bounce">
          <div class="sk-bounce1"></div>
          <div class="sk-bounce2"></div>
          <div class="sk-bounce3"></div>
        </div>
        <table class="footable table table-stripped toggle-arrow-tiny">
          <thead>
            <tr>
              <th>Date</th>
              <th>Product</th>
              <th>Qty</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let r of inventories">
              <td class="nowrap">{{ r.addedAt | date: "mediumDate" }}</td>
              <td>{{ r.title }}</td>
              <td class="nowrap">{{ r.quantity }}</td>
              <td>{{ r.description }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="5">
                <ul class="pagination float-right"></ul>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</div>
