<div class="middle-box text-center loginscreen animated fadeInDown">
  <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{ error }}</div>
  <div class="ibox-title">
    <h2>Profile</h2>
  </div>
  <div class="ibox-content">
    <div class="sk-spinner sk-spinner-three-bounce">
      <div class="sk-bounce1"></div>
      <div class="sk-bounce2"></div>
      <div class="sk-bounce3"></div>
    </div>

    <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <input
          type="text"
          placeholder="Fullname"
          formControlName="fullname"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.fullname.errors }"
        />
        <div *ngIf="submitted && f.fullname.errors" class="invalid-feedback">
          <div *ngIf="f.fullname.errors.required">Fullname is required</div>
        </div>
      </div>

      <div class="form-group">
        <input
          type="number"
          placeholder="Phone"
          formControlName="phone"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
        />
        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
          <div *ngIf="f.phone.errors.required">Phone is required</div>
        </div>
      </div>

      <div class="form-group">
        <input
          type="number"
          placeholder="Postal code"
          formControlName="pincode"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.pincode.errors }"
          (change)="loadAddress()"
        />
        <div *ngIf="submitted && f.pincode.errors" class="invalid-feedback">
          <div *ngIf="f.pincode.errors.required">Postal Code is required</div>
        </div>
      </div>

      <div class="form-group">
        <input
          type="text"
          placeholder="Block"
          formControlName="block"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.block.errors }"
        />
        <div *ngIf="submitted && f.block.errors" class="invalid-feedback">
          <div *ngIf="f.block.errors.required">Block number is required</div>
        </div>
      </div>

      <div class="form-group">
        <input
          type="number"
          placeholder="Floor"
          formControlName="floor"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.floor.errors }"
        />
        <div *ngIf="submitted && f.floor.errors" class="invalid-feedback">
          <div *ngIf="f.floor.errors.required">Floor number is required</div>
        </div>
      </div>

      <div class="form-group">
        <input
          type="number"
          placeholder="Unit"
          formControlName="unit"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.unit.errors }"
        />
        <div *ngIf="submitted && f.unit.errors" class="invalid-feedback">
          <div *ngIf="f.unit.errors.required">Unit number is required</div>
        </div>
      </div>

      <div class="form-group">
        <input
          type="text"
          placeholder="Road"
          formControlName="road"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.road.errors }"
        />
        <div *ngIf="submitted && f.road.errors" class="invalid-feedback">
          <div *ngIf="f.road.errors.required">Road is required</div>
        </div>
      </div>

      <div class="form-group">
        <button type="submit" class="btn btn-primary block full-width m-b">
          Update Profile
        </button>
      </div>
    </form>
  </div>
</div>
