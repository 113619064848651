<div class="row wrapper border-bottom white-bg page-heading">
  <h4 class="m-lg title">
    Welcome
    <span *ngIf="currentUser">{{ currentUser.email }}!</span>
    <span *ngIf="!currentUser">to Akshayam Organics!</span>
  </h4>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div *ngIf="message" class="alert alert-success alert-dismissable">
        <button
          aria-hidden="true"
          data-dismiss="alert"
          class="close"
          type="button"
        >
          ×
        </button>
        {{ message }}
      </div>

      <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-ride="carousel"
      >
        <ol class="carousel-indicators">
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to="0"
            class="active"
          ></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <a href="/products/oils">
              <img class="d-block w-100" src="assets/img/oil.png" alt="Oil" />
            </a>
          </div>
          <div class="carousel-item">
            <a href="/products/millets">
              <img
                class="d-block w-100"
                src="assets/img/millet.png"
                alt="Millet"
            /></a>
          </div>
          <div class="carousel-item">
            <a href="/products/sugars">
              <img class="d-block w-100" src="assets/img/sugar.png" alt="Sugar"
            /></a>
          </div>
        </div>
        <a
          class="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>

    <hr class="col-12" />

    <div class="scrolling-wrapper">
      <div class="item" *ngFor="let product of products">
        <div class="ibox-content product-box">
          <div class="product-imitation">
            <img src="{{ product.imageUrl }}" class="img-responsive" />
          </div>
          <div class="product-desc">
            <span class="product-price"> S$ {{ product.price }} </span>
            <small class="text-muted">{{ product.productType }}</small>
            <span class="product-name">{{ product.title }}</span>
            <div class="row">
              <div class="col-6">
                <a
                  [routerLink]="['/product-detail', product.id]"
                  class="btn btn-sm btn-outline btn-primary"
                  >Info <i class="fa fa-long-arrow-right"></i>
                </a>
              </div>
              <div class="col-6 text-right">
                <div (click)="cartPlus(product)" class="btn btn-sm btn-primary">
                  <i class="fa fa-plus"></i> Add to Cart
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-6 text-center">
      <img src="../../../assets/img/ios.png" class="download-image" />
    </div>
    <div class="col-6 text-center">
      <img src="../../../assets/img/android.png" class="download-image" />
    </div>

    <hr class="col-12" />
  </div>
</div>
