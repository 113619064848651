import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import * as moment from "moment";
import { User, Role } from "../_models";
import { AmplifyService } from "aws-amplify-angular";
import { API, graphqlOperation } from "aws-amplify";
import { createProfile, updateProfile } from "../../graphql/mutations";
import { listProfiles } from "../../graphql/queries";
import config from "../../aws-exports";
declare var Omise: any;

@Injectable({ providedIn: "root" })
export class AuthService {
  amplifyService = null;
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient, public amplify: AmplifyService) {
    this.amplifyService = amplify;
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  async signup(username, password, attributes) {
    const address =
      "Block " +
      attributes.block +
      ", #" +
      attributes.floor +
      "-" +
      attributes.unit +
      ", " +
      attributes.road;

    const userProfile = {
      username,
      fullname: attributes.fullname,
      phone: attributes.phone,
      pincode: attributes.pincode,
      address,
    };

    await API.graphql(graphqlOperation(createProfile, { input: userProfile }));

    try {
      await this.amplifyService.auth().signUp({ username, password });
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async loadProfile() {
    try {
      const user = await this.amplifyService.auth().currentAuthenticatedUser();
      const username = user.attributes.email;
      const groups =
        user.signInUserSession.accessToken.payload["cognito:groups"];
      const role = groups ? groups[0] : "";

      const response = await API.graphql(
        graphqlOperation(listProfiles, {
          filter: {
            username: {
              eq: username,
            },
          },
        })
      );

      let attributes = (response as any).data.listProfiles.items[0];

      attributes["block"] = attributes["address"]
        .split(",")[0]
        .replace("Block", "")
        .replace(" ", "");
      attributes["floor"] = attributes["address"]
        .split(",")[1]
        .split("-")[0]
        .replace("#", "")
        .replace(" ", "");
      attributes["unit"] = attributes["address"]
        .split(",")[1]
        .split("-")[1]
        .replace(" ", "");
      attributes["road"] = attributes["address"].split(",")[2].trim();

      const res = {
        currentUser: {
          email: username,
          role: role,
          attributes: attributes,
        },
        expiresIn: 1000,
        token: username,
      };
      this.setSession(res);
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async login(username, password) {
    try {
      await this.amplifyService.auth().signIn(username, password);
      this.loadProfile();
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async forgotPassword(username) {
    try {
      await this.amplifyService.auth().forgotPassword(username);
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async forgotPasswordSubmit(username, code, password) {
    try {
      await this.amplifyService
        .auth()
        .forgotPasswordSubmit(username, code, password);
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async changePassword(username, oldPassword, newPassword) {
    try {
      await this.amplifyService
        .auth()
        .changePassword(username, oldPassword, newPassword);
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async modifyProfile(attributes) {
    const address =
      "Block " +
      attributes.block +
      ", #" +
      attributes.floor +
      "-" +
      attributes.unit +
      ", " +
      attributes.road;

    const userProfile = {
      id: attributes.profileId,
      username: attributes.username,
      fullname: attributes.fullname,
      phone: attributes.phone,
      pincode: attributes.pincode,
      address,
    };

    try {
      await API.graphql(
        graphqlOperation(updateProfile, { input: userProfile })
      );
      this.loadProfile();
    } catch (error) {
      throw error;
    }
  }

  setSession(res: any) {
    this.currentUserSubject.next(res.currentUser);
    const expiresAt = moment().add(res.expiresIn, "second");
    localStorage.setItem("expiresAt", JSON.stringify(expiresAt.valueOf()));
    localStorage.setItem("token", res.token);
    localStorage.setItem("currentUser", JSON.stringify(res.currentUser));
  }

  logout() {
    this.amplifyService.auth().signOut();
    localStorage.removeItem("currentUser");
    localStorage.removeItem("token");
    localStorage.removeItem("expiresAt");
    this.currentUserSubject.next(null);
    localStorage.setItem("message", "Logged out successfully!");
  }

  public isLoggedIn() {
    return moment().isBefore(
      moment(JSON.parse(localStorage.getItem("expiresAt")))
    );
  }

  public isAdmin() {
    return this.currentUserValue && this.currentUserValue.role === Role.Admin;
  }

  getAddress(pincode: string) {
    return fetch(
      "https://developers.onemap.sg/commonapi/search?returnGeom=Y&getAddrDetails=Y&searchVal=" +
        pincode
    )
      .then((response) => response.json())
      .then((json) => {
        if (json.results.length > 0) {
          return json.results[0];
        }
      });
  }

  chargeOmise(card: any) {
    console.log(card);
    Omise.setPublicKey(config.pkey);

    Omise.createToken("card", card, function (statusCode, response) {
      if (response.object == "error" || !response.card.security_code_check) {
        var message_text = "SET YOUR SECURITY CODE CHECK FAILED MESSAGE";
        if (response.object == "error") {
          message_text = response.message;
        }
        console.log(message_text);
        return message_text;
      } else {
        console.log("token ==> ", response["id"]);
        return response["id"];
      }
    });
    // return this.http.post<any>(config.paymentApi, data);
  }
}
