import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PortalService } from "../../_services";

@Component({
  selector: "app-product-detail",
  templateUrl: "./product-detail.component.html",
  styleUrls: ["./product-detail.component.css"],
})
export class ProductDetailComponent implements OnInit {
  product: any;
  message: any;
  private sub: any;

  constructor(
    private route: ActivatedRoute,
    private portalService: PortalService
  ) {}

  ngOnInit(): void {
    this.message = "";
    this.product = null;
    this.sub = this.route.params.subscribe((params) => {
      this.portalService.getProduct(params["id"]).then((response) => {
        this.product = response;
      });
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  cartPlus(product: any) {
    this.message = "";
    this.portalService.cartPlus(product).then(() => {
      this.message = "Added to cart successfully!";
    });
  }
}
