<div class="row border-bottom white-bg">
  <nav class="navbar navbar-expand-lg navbar-static-top" role="navigation">
    <a href="#"><img src="../../../assets/img/logo.png" class="logo" /></a>
    <app-cart-icon></app-cart-icon>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbar"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <i class="fa fa-reorder"></i>
    </button>

    <div class="navbar-collapse collapse" id="navbar">
      <ul class="nav navbar-nav mr-auto">
        <li class="dropdown">
          <a
            aria-expanded="false"
            role="button"
            href="#"
            class="dropdown-toggle product-name"
            data-toggle="dropdown"
          >
            <i class="fa fa-lg fa-shopping-basket"></i>Products</a
          >
          <ul role="menu" class="dropdown-menu">
            <li>
              <a href="/products/oils" class="product-name"
                ><i class="fa fa-lg fa-long-arrow-right subitem"></i>Cold
                Pressed Oil</a
              >
            </li>
            <li>
              <a href="/products/millets" class="product-name"
                ><i class="fa fa-lg fa-long-arrow-right subitem"></i>Millets</a
              >
            </li>
            <li>
              <a href="/products/sugars" class="product-name">
                <i class="fa fa-lg fa-long-arrow-right subitem"></i>Sugars</a
              >
            </li>
          </ul>
        </li>
        <li>
          <a href="/orders" class="product-name">
            <i class="fa fa-lg fa-list"></i
            ><span class="nav-label subitem">My Orders</span>
          </a>
        </li>
        <li *ngIf="currentUser">
          <a href="/profile" class="product-name">
            <i class="fa fa-lg fa-user"></i
            ><span class="nav-label subitem">Profile</span>
          </a>
        </li>
        <li>
          <a href="/contact-us" class="product-name">
            <i class="fa fa-lg fa-phone"></i
            ><span class="nav-label subitem">Contact</span>
          </a>
        </li>
        <li *ngIf="isAdmin" class="dropdown">
          <a
            aria-expanded="false"
            role="button"
            href="#"
            class="dropdown-toggle product-name"
            data-toggle="dropdown"
          >
            <i class="fa fa-lg fa-lock"></i>Admin</a
          >
          <ul role="menu" class="dropdown-menu">
            <li>
              <a href="/admin/inventory" class="product-name"
                ><i class="fa fa-lg fa-long-arrow-right subitem"></i
                >Inventory</a
              >
            </li>
            <li>
              <a href="/admin/stock" class="product-name"
                ><i class="fa fa-lg fa-long-arrow-right subitem"></i>Stock</a
              >
            </li>
            <li>
              <a href="/admin/sales" class="product-name">
                <i class="fa fa-lg fa-long-arrow-right subitem"></i>Sales</a
              >
            </li>
          </ul>
        </li>
      </ul>
      <ul class="nav navbar-top-links navbar-right">
        <li *ngIf="!currentUser">
          <a href="/login">
            <i class="fa fa-lg fa-sign-in"></i
            ><span class="font16">Login</span></a
          >
        </li>
        <li *ngIf="currentUser">
          <a href="#" (click)="userLogout()">
            <i class="fa fa-lg fa-sign-out"></i
            ><span class="font16">Logout</span>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</div>
