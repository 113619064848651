import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PortalService } from "./../../_services";
declare var jQuery: any;

@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.css"],
})
export class PaymentComponent implements OnInit {
  error = "";
  order: any;

  constructor(private portalService: PortalService, private router: Router) {}

  ngOnInit(): void {
    this.error = "";
    this.order = JSON.parse(localStorage.getItem("order"));
  }

  toggleSpinner() {
    jQuery(".ibox-content").toggleClass("sk-loading");
  }

  addOrder(paymentType) {
    this.portalService
      .addOrder(paymentType)
      .then((res) => {
        localStorage.setItem("message", "Order has been placed successfully!");
        this.router.navigateByUrl("/orders");
      })
      .catch((err) => {
        this.error = err;
      });
  }
}
