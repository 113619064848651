import { Component, OnInit } from "@angular/core";
import { PortalService } from "../../_services";
declare var jQuery: any;

@Component({
  selector: "app-inventory",
  templateUrl: "./inventory.component.html",
  styleUrls: ["./inventory.component.css"],
})
export class InventoryComponent implements OnInit {
  inventories: Array<any>;

  constructor(private portalService: PortalService) {}

  ngOnInit(): void {
    jQuery(".ibox-content").toggleClass("sk-loading");
    this.loadInventories();
  }

  loadInventories() {
    this.inventories = [];
    this.portalService.fetchInventories().then((response) => {
      response.sort((x, y) => +new Date(y.addedAt) - +new Date(x.addedAt));
      this.inventories = response;
      jQuery(".ibox-content").toggleClass("sk-loading");
    });
  }
}
