import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "./../../_services";
import { User } from "./../../_models";
declare var jQuery: any;

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit, OnDestroy {
  currentUser: User;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService
  ) {
    this.currentUser = this.authService.currentUserValue;
  }

  profileForm: FormGroup;
  submitted = false;
  error = "";

  ngOnInit(): void {
    this.error = "";
    this.profileForm = this.formBuilder.group({
      fullname: [this.currentUser.attributes.fullname, [Validators.required]],
      phone: [
        this.currentUser.attributes.phone,
        [Validators.required, Validators.minLength(8), Validators.maxLength(8)],
      ],
      pincode: [
        this.currentUser.attributes.pincode,
        [Validators.required, Validators.minLength(6), Validators.maxLength(6)],
      ],
      block: [this.currentUser.attributes.block, [Validators.required]],
      floor: [this.currentUser.attributes.floor, [Validators.required]],
      unit: [this.currentUser.attributes.unit, [Validators.required]],
      road: [this.currentUser.attributes.road, [Validators.required]],
    });
  }

  ngOnDestroy(): void {}

  toggleSpinner() {
    jQuery(".ibox-content").toggleClass("sk-loading");
  }

  get f() {
    return this.profileForm.controls;
  }

  loadAddress() {
    this.authService
      .getAddress(this.profileForm.get("pincode").value)
      .then((res) => {
        this.profileForm.patchValue({
          block: res["BLK_NO"],
          road: res["ROAD_NAME"],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onSubmit() {
    this.submitted = true;
    if (this.profileForm.invalid) {
      return;
    }

    this.toggleSpinner();

    const attributes = {
      profileId: this.currentUser.attributes.id,
      username: this.currentUser.email,
      fullname: this.f.fullname.value.toString(),
      phone: this.f.phone.value.toString(),
      pincode: this.f.pincode.value.toString(),
      block: this.f.block.value.toString(),
      floor: this.f.floor.value.toString(),
      unit: this.f.unit.value.toString(),
      road: this.f.road.value.toString(),
    };
    this.authService
      .modifyProfile(attributes)
      .then(() => {
        localStorage.setItem("message", "Profile changed successfully!");
        this.router.navigateByUrl("/home");
      })
      .catch((error) => {
        console.log(error);
        this.error = error;
        this.toggleSpinner();
      });
  }
}
