import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

import Amplify from "aws-amplify";

Amplify.configure({
  Auth: {
    identityPoolId: "us-east-2_PvDy2ws1s",
    region: "us-east-2",
    identityPoolRegion: "us-east-2",
    userPoolId: "us-east-2_PvDy2ws1s",
    userPoolWebClientId: "2io3422kjseld7e8f8d2aqpq2r",
    authenticationFlowType: "USER_SRP_AUTH",
  },
  API: {
    aws_project_region: "us-east-2",
    aws_appsync_graphqlEndpoint:
      "https://2vwpwtwzrbfv5mobbl2idawx6e.appsync-api.us-east-2.amazonaws.com/graphql",
    aws_appsync_region: "us-east-2",
    aws_appsync_authenticationType: "API_KEY",
    aws_appsync_apiKey: "da2-oi2vsu2fazg7hjwdtinwytf2ie",
  },
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
