import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "./../../_services";
declare var jQuery: any;

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService
  ) {}

  sendCodeForm: FormGroup;
  verifyCodeForm: FormGroup;
  submitted = false;
  success = "";
  error = "";
  sendCodeFlag: Boolean;

  ngOnInit(): void {
    this.success = "";
    this.error = "";
    this.sendCodeFlag = true;
    this.sendCodeForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
    });
    this.verifyCodeForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(8)]],
      code: ["", [Validators.required]],
    });
  }

  ngOnDestroy(): void {}

  toggleSpinner() {
    jQuery(".ibox-content").toggleClass("sk-loading");
  }

  get f() {
    return this.sendCodeForm.controls;
  }

  get f1() {
    return this.verifyCodeForm.controls;
  }

  sendCode() {
    this.submitted = true;
    if (this.sendCodeForm.invalid) {
      return;
    }

    this.toggleSpinner();
    this.authService
      .forgotPassword(this.f.email.value)
      .then(() => {
        this.sendCodeFlag = false;
        this.success = "Code has been sent to your email";
        this.submitted = false;
        this.toggleSpinner();
      })
      .catch((error) => {
        console.log(error);
        this.error = error;
        this.toggleSpinner();
      });
  }

  verifyCode() {
    this.submitted = true;
    this.success = "";
    this.error = "";
    if (this.verifyCodeForm.invalid) {
      return;
    }

    this.toggleSpinner();
    this.authService
      .forgotPasswordSubmit(
        this.f1.email.value,
        this.f1.code.value,
        this.f1.password.value
      )
      .then(() => {
        this.router.navigateByUrl("/login");
      })
      .catch((error) => {
        console.log(error);
        this.error = error;
        this.toggleSpinner();
      });

    this.router.navigateByUrl("/");
  }
}
