import { Component, OnInit } from "@angular/core";
import { PortalService } from "../../_services";

@Component({
  selector: "app-oils",
  templateUrl: "./oils.component.html",
  styleUrls: ["./oils.component.css"],
})
export class OilsComponent implements OnInit {
  message: any;
  products: Array<any>;

  constructor(private portalService: PortalService) {}

  ngOnInit(): void {
    this.message = "";
    this.products = [];
    this.portalService.getProducts("oil").then((response) => {
      this.products = response;
    });
  }

  cartPlus(product: any) {
    this.portalService.cartPlus(product).then(() => {
      this.message = "Added to cart successfully!";
    });
  }
}
