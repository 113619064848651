import { Component, OnInit, OnDestroy } from "@angular/core";
import { AuthService, PortalService } from "./../../_services";
import { User } from "./../../_models";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit, OnDestroy {
  currentUser: User;
  message: any;
  products: Array<any>;

  constructor(
    private authService: AuthService,
    private portalService: PortalService
  ) {
    this.currentUser = this.authService.currentUserValue;
  }

  ngOnInit(): void {
    this.message = localStorage.getItem("message");
    localStorage.removeItem("message");
    this.products = [];
    this.portalService.getAllProducts().then((response) => {
      this.products = response;
    });
  }

  ngOnDestroy(): void {
    localStorage.removeItem("message");
  }

  cartPlus(product: any) {
    this.portalService.cartPlus(product).then(() => {
      this.message = "Added to cart successfully!";
    });
  }
}
