import { Component, OnInit } from "@angular/core";
import { AuthService } from "./../../_services";
import { User } from "./../../_models";
import { PortalService } from "../../_services";
declare var jQuery: any;

@Component({
  selector: "app-orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.css"],
})
export class OrdersComponent implements OnInit {
  message: any;
  currentUser: User;
  orders: Array<any>;
  pendingOrders: Array<any>;
  completedOrders: Array<any>;
  status: string;
  isAdmin: boolean;

  constructor(
    private authService: AuthService,
    private portalService: PortalService
  ) {
    this.authService.currentUser.subscribe((x) => (this.currentUser = x));
  }

  ngOnInit(): void {
    this.isAdmin = this.authService.isAdmin();
    this.message = localStorage.getItem("message");
    localStorage.removeItem("message");
    this.loadOrders();
  }

  loadOrders() {
    if (!this.currentUser) {
      return;
    }
    jQuery(".ibox-content").toggleClass("sk-loading");
    this.status = "pending";
    this.orders = [];
    this.pendingOrders = [];
    this.completedOrders = [];

    this.portalService.getOrders(this.currentUser).then((response) => {
      response.sort((x, y) => +new Date(y.date) - +new Date(x.date));
      response.forEach((order) => {
        if (order.status !== "completed") {
          this.pendingOrders.push(order);
        } else {
          this.completedOrders.push(order);
        }
      });
      jQuery(".ibox-content").toggleClass("sk-loading");
      this.showOrders();
    });
  }

  showOrders(status = this.status): void {
    jQuery(".ibox-content").toggleClass("sk-loading");
    this.orders = [];
    this.status = status;
    if (this.status === "pending") {
      this.orders = this.pendingOrders;
    } else {
      this.orders = this.completedOrders;
    }

    jQuery(document).ready(function () {
      setTimeout(() => {
        jQuery(".footable").trigger("footable_initialize");
        jQuery(".ibox-content").toggleClass("sk-loading");
      }, 1000);
    });
  }
}
