import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LayoutComponent } from "./components/layouts/layout.component";

import { HomeComponent } from "./components/home/home.component";
import { LoginComponent } from "./components/login/login.component";
import { RegisterComponent } from "./components/register/register.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";

import { AuthGuard } from "./_helpers";
import { Role } from "./_models";

import { ContactUsComponent } from "./components/contact-us/contact-us.component";
import { ProductDetailComponent } from "./components/product-detail/product-detail.component";
import { OrdersComponent } from "./components/orders/orders.component";
import { CartComponent } from "./components/cart/cart.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { PaymentComponent } from "./components/payment/payment.component";
import { OilsComponent } from "./components/oils/oils.component";
import { MilletsComponent } from "./components/millets/millets.component";
import { SugarsComponent } from "./components/sugars/sugars.component";

import { InventoryComponent } from "./components/inventory/inventory.component";
import { StockComponent } from "./components/stock/stock.component";
import { SalesComponent } from "./components/sales/sales.component";

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  {
    path: "",
    component: LayoutComponent,
    children: [
      {
        path: "register",
        component: RegisterComponent,
      },
      {
        path: "login",
        component: LoginComponent,
      },
      {
        path: "forgot-password",
        component: ForgotPasswordComponent,
      },
      {
        path: "contact-us",
        component: ContactUsComponent,
      },
    ],
  },
  {
    path: "",
    component: LayoutComponent,
    children: [
      {
        path: "home",
        component: HomeComponent,
      },
      {
        path: "profile",
        component: ProfileComponent,
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin, Role.User] },
      },
      {
        path: "payment",
        component: PaymentComponent,
      },
      {
        path: "product-detail/:id",
        component: ProductDetailComponent,
      },
      {
        path: "orders",
        component: OrdersComponent,
      },
      {
        path: "cart",
        component: CartComponent,
      },
    ],
  },
  {
    path: "products",
    component: LayoutComponent,
    children: [
      {
        path: "oils",
        component: OilsComponent,
      },
      {
        path: "millets",
        component: MilletsComponent,
      },
      {
        path: "sugars",
        component: SugarsComponent,
      },
    ],
  },
  {
    path: "admin",
    component: LayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] },
    children: [
      {
        path: "inventory",
        component: InventoryComponent,
      },
      {
        path: "stock",
        component: StockComponent,
      },
      {
        path: "sales",
        component: SalesComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
