import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { LayoutComponent } from "./components/layouts/layout.component";

import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { HomeComponent } from "./components/home/home.component";
import { LoginComponent } from "./components/login/login.component";
import { RegisterComponent } from "./components/register/register.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";

import { JwtInterceptor, ErrorInterceptor } from "./_helpers";

import { AmplifyAngularModule, AmplifyService } from "aws-amplify-angular";

import { ContactUsComponent } from "./components/contact-us/contact-us.component";
import { OrdersComponent } from "./components/orders/orders.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { PaymentComponent } from "./components/payment/payment.component";
import { OilsComponent } from "./components/oils/oils.component";
import { MilletsComponent } from "./components/millets/millets.component";
import { SugarsComponent } from "./components/sugars/sugars.component";
import { CartComponent } from "./components/cart/cart.component";
import { ProductDetailComponent } from "./components/product-detail/product-detail.component";
import { CartIconComponent } from "./components/cart-icon/cart-icon.component";
import { InventoryComponent } from "./components/inventory/inventory.component";
import { StockComponent } from "./components/stock/stock.component";
import { SalesComponent } from "./components/sales/sales.component";

@NgModule({
  declarations: [
    LayoutComponent,
    AppComponent,
    FooterComponent,
    ForgotPasswordComponent,
    HeaderComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    ContactUsComponent,
    OrdersComponent,
    ProfileComponent,
    PaymentComponent,
    OilsComponent,
    MilletsComponent,
    SugarsComponent,
    CartComponent,
    ProductDetailComponent,
    CartIconComponent,
    InventoryComponent,
    StockComponent,
    SalesComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AmplifyAngularModule,
  ],
  providers: [
    AmplifyService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
