<ul class="nav navbar-top-links navbar-right">
  <li>
    <a href="/cart">
      <i class="fa fa-lg fa-2x fa-shopping-cart">
        <span *ngIf="count !== 0" class="badge badge-danger">
          {{ count }}
        </span>
      </i>
    </a>
  </li>
</ul>
