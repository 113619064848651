import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "./../../_services";
declare var jQuery: any;

@Component({
  selector: "app-login",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService
  ) {
    if (this.authService.currentUserValue) {
      this.router.navigate(["/"]);
    }
  }

  registerForm: FormGroup;
  submitted = false;
  error = "";

  ngOnInit(): void {
    this.error = "";
    this.registerForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(8)]],
      fullname: ["", [Validators.required]],
      phone: [
        "",
        [Validators.required, Validators.minLength(8), Validators.maxLength(8)],
      ],
      pincode: [
        "",
        [Validators.required, Validators.minLength(6), Validators.maxLength(6)],
      ],
      block: ["", [Validators.required]],
      floor: ["", [Validators.required]],
      unit: ["", [Validators.required]],
      road: ["", [Validators.required]],
    });
  }

  ngOnDestroy(): void {}

  toggleSpinner() {
    jQuery(".ibox-content").toggleClass("sk-loading");
  }

  get f() {
    return this.registerForm.controls;
  }

  loadAddress() {
    this.authService
      .getAddress(this.registerForm.get("pincode").value)
      .then((res) => {
        this.registerForm.patchValue({
          block: res["BLK_NO"],
          road: res["ROAD_NAME"],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }

    this.toggleSpinner();

    const attributes = {
      fullname: this.f.fullname.value.toString(),
      phone: this.f.phone.value.toString(),
      pincode: this.f.pincode.value.toString(),
      block: this.f.block.value.toString(),
      floor: this.f.floor.value.toString(),
      unit: this.f.unit.value.toString(),
      road: this.f.road.value.toString(),
    };
    this.authService
      .signup(this.f.email.value, this.f.password.value, attributes)
      .then(() => {
        this.router.navigateByUrl("/login");
      })
      .catch((error) => {
        console.log(error);
        this.error = error;
        this.toggleSpinner();
      });
  }
}
