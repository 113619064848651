import { Component, OnInit } from "@angular/core";
import { PortalService } from "../../_services/portal.service";

@Component({
  selector: "app-cart-icon",
  templateUrl: "./cart-icon.component.html",
  styleUrls: ["./cart-icon.component.css"],
})
export class CartIconComponent implements OnInit {
  count;
  constructor(private portalService: PortalService) {}

  ngOnInit(): void {
    this.count = 0;
    const cartItems = JSON.parse(localStorage.getItem("cart"));
    if (cartItems !== null) {
      this.count = cartItems.count;
    }

    this.portalService.getCartCount().subscribe((value) => {
      this.count = value;
    });
  }
}
