import { Component } from "@angular/core";

@Component({
  selector: "app-layout",
  template: `
    <div id="wrapper">
      <div id="page-wrapper" class="gray-bg">
        <app-header></app-header>
        <router-outlet></router-outlet>
        <app-footer></app-footer>
      </div>
    </div>
  `,
})
export class LayoutComponent {}
