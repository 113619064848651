import { Component, OnInit } from "@angular/core";
import { AuthService } from "./../../_services";
import { User } from "./../../_models";
import { Router } from "@angular/router";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  currentUser: User;
  url: String;
  isAdmin: boolean;

  constructor(private authService: AuthService, private router: Router) {
    this.authService.currentUser.subscribe((x) => {
      this.currentUser = x;
      this.isAdmin = this.authService.isAdmin();
    });
  }

  ngOnInit(): void {
    this.url = this.router.url;
  }

  userLogout(): void {
    this.authService.logout();
  }
}
