<div class="row wrapper border-bottom white-bg page-heading">
  <h2>Payment</h2>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox">
        <div class="ibox-content">
          <div class="tabs-container">
            <ul class="nav nav-tabs" role="tablist">
              <li>
                <a class="nav-link active" data-toggle="tab" href="#cash-tab">
                  Cash</a
                >
              </li>
              <li>
                <a class="nav-link" data-toggle="tab" href="#paynow-tab"
                  >PayNow</a
                >
              </li>
            </ul>
            <div class="tab-content">
              <div role="tabpanel" id="cash-tab" class="tab-pane active">
                <div class="panel-body">
                  <p>
                    For Cash-On-Delivery, skip the payment and place the order
                  </p>
                  <button class="btn btn-primary" (click)="addOrder('cash')">
                    Skip Payment
                  </button>
                </div>
              </div>
              <div role="tabpanel" id="paynow-tab" class="tab-pane">
                <div class="panel-body">
                  <p>PayNow to below UEN and continue to place the order</p>
                  <strong>UEN: 201610687H</strong><br />
                  <strong>AARUPADAI IMPEX PTE. LTD.</strong><br /><br />
                  <button class="btn btn-primary" (click)="addOrder('paynow')">
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
