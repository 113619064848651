<div class="middle-box text-center loginscreen animated fadeInDown">
  <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{ error }}</div>
  <div class="ibox-title">
    <h2>Login</h2>
  </div>
  <div class="ibox-content" style="min-height: 0">
    <div class="sk-spinner sk-spinner-three-bounce">
      <div class="sk-bounce1"></div>
      <div class="sk-bounce2"></div>
      <div class="sk-bounce3"></div>
    </div>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <input
          type="text"
          placeholder="Username"
          formControlName="username"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
        />
        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
          <div *ngIf="f.username.errors.required">Username is required</div>
          <div *ngIf="f.username.errors.email">Username is invalid</div>
        </div>
      </div>
      <div class="form-group">
        <input
          type="password"
          placeholder="Password"
          formControlName="password"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
        />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">Password is required</div>
        </div>
      </div>
      <div class="form-group">
        <button type="submit" class="btn btn-primary block full-width m-b">
          Login
        </button>
      </div>
      <a href="#" [routerLink]="['/forgot-password']"
        ><small>Forgot password?</small></a
      >
      <p class="text-muted text-center">
        <small>Do not have an account?</small>
      </p>
      <a
        class="btn btn-sm btn-white btn-block"
        href="#"
        [routerLink]="['/register']"
        >Create an account</a
      >
    </form>
  </div>
</div>
