<div class="row wrapper border-bottom white-bg page-heading">
  <h2>Orders</h2>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div
      *ngIf="message"
      class="m-md col-lg-12 alert alert-success alert-dismissable"
    >
      <button
        aria-hidden="true"
        data-dismiss="alert"
        class="close"
        type="button"
      >
        ×
      </button>
      {{ message }}
    </div>
    <div class="ibox">
      <div class="ibox-content">
        <div class="sk-spinner sk-spinner-three-bounce">
          <div class="sk-bounce1"></div>
          <div class="sk-bounce2"></div>
          <div class="sk-bounce3"></div>
        </div>

        <div *ngIf="!currentUser">
          Kindly <a href="/login">Login </a> to place order
        </div>

        <div *ngIf="currentUser">
          <div class="tabs-container">
            <ul class="nav nav-tabs" role="tablist">
              <li (click)="showOrders('pending')">
                <a class="nav-link active" data-toggle="tab">Pending</a>
              </li>
              <li (click)="showOrders('completed')">
                <a class="nav-link" data-toggle="tab">Completed</a>
              </li>
            </ul>

            <table class="footable table table-stripped toggle-arrow-tiny">
              <thead>
                <tr>
                  <th data-toggle="true">Order #</th>
                  <th>Order Date</th>
                  <th>Status</th>
                  <th>Total</th>
                  <th data-hide="all"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let r of orders; index as idx">
                  <td>{{ r.orderId }}</td>
                  <td class="nowrap">{{ r.date | date: "mediumDate" }}</td>
                  <td class="{{ r.status }}-status nowrap">
                    {{ r.status | titlecase }}
                  </td>
                  <td class="text-right amount">
                    <b>{{ r.totalAmount | number: "1.2-2" }}</b>
                  </td>
                  <td>
                    <div class="row col-sm-12">
                      <div class="col-sm-6 nowrap">
                        <span *ngIf="isAdmin">
                          <i class="fa fa-lg fa-user-circle"></i>
                          {{ r.fullname }}
                          <i class="fa fa-lg fa-phone"></i>
                          {{ r.phone }}
                          <br />
                          <i class="fa fa-lg fa-map-marker"></i>
                          <small>{{ r.address }}</small>
                          <br />
                          <i class="fa fa-lg fa-map-pin"></i>
                          {{ r.pincode }}
                          <br />
                        </span>
                        <i class="fa fa-lg fa-calendar"></i> Expected Date:
                        {{ r.expectedAt | date: "mediumDate" }}
                        <br />
                        <i class="fa fa-lg fa-location-arrow"></i>
                        <span
                          class="{{
                            r.deliveredAt ? 'text-primary' : 'text-danger'
                          }}"
                        >
                          {{
                            r.deliveredAt
                              ? "Delivered on: " +
                                (r.deliveredAt | date: "mediumDate")
                              : "Pending"
                          }}
                        </span>
                        <br />
                        <i class="fa fa-lg fa-money"></i>
                        <span
                          class="{{
                            r.paidAt ? 'text-primary' : 'text-danger'
                          }}"
                        >
                          {{
                            r.paidAt
                              ? "Paid on: " + (r.paidAt | date: "mediumDate")
                              : "Not Paid"
                          }}
                        </span>
                      </div>
                      <div class="col-sm-6">
                        <table class="table table-bordered">
                          <tr>
                            <th>Product</th>
                            <th>#</th>
                            <th>Amount</th>
                          </tr>
                          <tr *ngFor="let i of r.items">
                            <td class="nowrap">{{ i.productTitle }}</td>
                            <td class="text-center">{{ i.quantity }}</td>
                            <td>{{ i.sum | number: "1.2-2" }}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="5">
                    <ul class="pagination float-right"></ul>
                  </td>
                </tr>
              </tfoot>
            </table>

            <div
              *ngIf="orders.length === 0"
              class="row alert alert-danger animated bouncedIn"
            >
              No {{ status }} orders found!
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
