<div class="row wrapper border-bottom white-bg page-heading">
  <h2>Sales</h2>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="ibox">
      <div class="ibox-content">
        <div class="sk-spinner sk-spinner-three-bounce">
          <div class="sk-bounce1"></div>
          <div class="sk-bounce2"></div>
          <div class="sk-bounce3"></div>
        </div>

        <label>Date Range:</label>
        <span class="nowrap">
          <input
            type="date"
            id="fromDate"
            name="fromDate"
            [(ngModel)]="fromDate"
          />
          to
          <input type="date" id="toDate" name="toDate" [(ngModel)]="toDate" />
        </span>

        <hr />

        <div class="row">
          <div class="col-6">
            <button class="btn btn-xs btn-primary" (click)="loadSales()">
              Apply
            </button>
          </div>
          <div class="col-6 text-right">
            <h4>
              Total: <b>S$ {{ total | number: "1.2-2" }}</b>
            </h4>
          </div>
        </div>
        <hr />

        <table class="footable table table-stripped toggle-arrow-tiny">
          <thead>
            <tr>
              <th>Product</th>
              <th>Qty</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let r of sales">
              <td>{{ r.title }}</td>
              <td class="nowrap">{{ r.quantity }}</td>
              <td>{{ r.sum | number: "1.2-2" }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="5">
                <ul class="pagination float-right"></ul>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</div>
