<div class="row wrapper border-bottom white-bg page-heading">
  <h2>Cart</h2>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox">
        <div class="ibox-content">
          <div *ngIf="count === 0">
            <h2>Cart is empty</h2>
            <div class="col-lg-12 text-center">
              <img src="../../../assets/img/cart.png" />
            </div>
            <div class="m-md text-center">
              <a href="/home" class="btn btn-sm btn-primary"
                ><i class="fa fa-long-arrow-right"></i> Start Shopping
              </a>
            </div>
          </div>
          <div *ngIf="count !== 0" class="text-right">
            <h4>
              Total Amount : <b>S$ {{ total | number: "1.2-2" }}</b>
            </h4>
          </div>
          <table *ngIf="count !== 0" class="table">
            <tbody>
              <tr *ngFor="let p of products">
                <td>{{ p.title }}</td>
                <td class="nowrap">
                  <i
                    (click)="cartMinus(p)"
                    class="fa fa-minus btn btn-sm btn-outline btn-danger"
                  ></i>
                  <b class="m-md">{{ p.quantity }}</b>
                  <i
                    (click)="cartPlus(p)"
                    class="fa fa-plus btn btn-sm btn-outline btn-primary"
                  ></i>
                </td>
                <td class="text-right">{{ p.sum | number: "1.2-2" }}</td>
              </tr>
            </tbody>
          </table>

          <div *ngIf="count !== 0" class="text-right">
            <div *ngIf="!currentUser">
              Kindly <a href="/login">Login </a> to place order
            </div>
            <div *ngIf="currentUser">
              <a href="/payment" class="btn btn-sm btn-primary"
                ><i class="fa fa-long-arrow-right"></i> Checkout
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
