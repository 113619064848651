import { Component, OnInit } from "@angular/core";
import { PortalService } from "../../_services";
declare var jQuery: any;

@Component({
  selector: "app-sales",
  templateUrl: "./sales.component.html",
  styleUrls: ["./sales.component.css"],
})
export class SalesComponent implements OnInit {
  sales: Array<any>;
  total: any;
  fromDate: any;
  toDate: any;

  constructor(private portalService: PortalService) {}

  ngOnInit(): void {
    this.total = 0;
    this.fromDate = "2021-01-01";
    this.toDate = "2021-12-31";
    this.loadSales();
  }

  loadSales() {
    console.log(this.fromDate, this.toDate);
    jQuery(".ibox-content").toggleClass("sk-loading");
    this.sales = [];
    this.portalService
      .fetchSales(this.fromDate, this.toDate)
      .then((response) => {
        this.sales = response.sales;
        this.total = response.total;
        jQuery(".ibox-content").toggleClass("sk-loading");
      });
  }
}
