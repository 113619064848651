<div class="row wrapper border-bottom white-bg page-heading">
  <h2 *ngIf="product">{{ product.title }}</h2>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div
      *ngIf="message"
      class="m-md col-lg-12 alert alert-success alert-dismissable"
    >
      <button
        aria-hidden="true"
        data-dismiss="alert"
        class="close"
        type="button"
      >
        ×
      </button>
      {{ message }}
    </div>
    <div class="ibox" *ngIf="product">
      <div class="ibox-content product-box">
        <div class="product-imitation">
          <img src="{{ product.imageUrl }}" class="img-responsive" />
        </div>
        <div class="product-desc">
          <span class="product-price"> S$ {{ product.price }} </span>
          <div class="row">
            <div class="col-6">
              <a href="#" class="product-name">{{ product.title }}</a>
            </div>
            <div class="col-6 text-right">
              <div (click)="cartPlus(product)" class="btn btn-sm btn-primary">
                <i class="fa fa-plus"></i> Add to Cart
              </div>
            </div>
          </div>
          <div [innerHTML]="product.description"></div>
        </div>
      </div>
    </div>
  </div>
</div>
