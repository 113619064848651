/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

export const awsmobile = {
  aws_project_region: "us-east-2",
  aws_appsync_graphqlEndpoint:
    "https://2vwpwtwzrbfv5mobbl2idawx6e.appsync-api.us-east-2.amazonaws.com/graphql",
  aws_appsync_region: "us-east-2",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-oi2vsu2fazg7hjwdtinwytf2ie",
  aws_cognito_region: "us-east-2",
  aws_user_pools_id: "us-east-2_PvDy2ws1s",
  aws_user_pools_web_client_id: "2io3422kjseld7e8f8d2aqpq2r",
  oauth: {},
  accessKeyId: "AKIA5PH2FLQX2WV5DOXI",
  secretAccessKey: "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",
  fromEmail: "akshayamorganics@gmail.com",
  toEmail: "venugopalant@gmail.com",
  pkey: "pkey_test_5n6zpnpe2a6tweozbyb",
  paymentApi:
    "https://dzs2fyx0hl.execute-api.us-east-2.amazonaws.com/app-omise",
};

export default awsmobile;
